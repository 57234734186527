<template lang="">
  <div>
  </div>
</template>

<script>
export default {
  install (Vue) {
    // 重複クリック防止
    Vue.directive('preventReClick', {
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 1000)
          }
        })
      }
    })
  }
}
</script>
