import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import App from './App.vue'
import router from './router'
import TreeTable from 'vue-table-with-tree-grid'
import preventReClick from '../src/components/Home/Disable.vue'
import md5 from 'js-md5'
import axios from 'axios'
import qs from 'qs'
import url from './config/url.js'
import './assets/css/global.css'
import Router from 'vue-router'
import ElementUI, { Loading } from 'element-ui';//全局引入element
import locale from 'element-ui/lib/locale/lang/ja'
import store from './store'
Vue.use(ElementUI, { locale }, store);     //全局注入element
Vue.use(Loading.directive);
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(preventReClick)
Vue.prototype.$md5 = md5
Vue.prototype.$http = axios
Vue.prototype.$qs = qs
Vue.prototype.$url = url
// axios.interceptors.response.use(function (response) {

//   if (response.data.message === 'AuthFailure' || response.data.message === 'AuthFailureSinceHaveNoAuthMsg') {
//     localStorage.clear();
//     // router.replace({ path: "/GetLogin" });
//   }
//   return response
// }, function (error) {
//   return Promise.reject(error)
// })

Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


