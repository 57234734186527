let URL = 'http://backapi.drtech.jp/' //本番環境
// let URL = 'http://10.0.0.68/drtechecshop/' //ローカル環境
// const URL = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_SERVER_URL
export default {
  url: URL + 'uploads/',
  urls: URL + 'uploads/others/',
  urlss: URL + 'uploads/exportFiles/',
  urlcq: URL + 'uploads/avatars/',
  urlcqs: URL + 'uploads/',
  merchantId: 'DUMMY',
  // allFiles: URL + "uploads/allFiles/",
  login: URL + "m_login",//ログイン
  UpdateMyPassword: (oldPassword, newPassword) =>
    URL + `api/Management/Staff/UpdateMyPassword/${oldPassword}/${newPassword}`, //パスワード変更
  ForgotPassword: URL + "api/Management/Staff/ForgotPassword", //

  //商品管理
  GetProductList: URL + "m_getProductList", //商品リスト
  GetProductListWithPatterns: URL + "api/Management/Products/GetProductListWithPatterns", //商品リスト
  GetProductDetail: URL + "api/Management/Products/GetProductDetail", //商品詳細
  AddProduct: URL + "api/Management/Products/AddProduct", //商品新規
  UpdateProduct: URL + "api/Management/Products/UpdateProduct", //商品編集
  DeleteProduct: URL + "api/Management/Products/DeleteProduct", //削除商品
  UploadPicsTt: URL + "api/Management/FileUpload/Upload", //上传文件
  UploadAvatars: URL + "api/Management/FileUpload/UploadAvatars", //写真のアップロード300*300
  UploadPicture: URL + "m_uploadFile", //写真のアップロード（解析度フリー）
  ConfirmProduct: URL + "api/Management/Products/ConfirmProduct", //商品登録審査
  RejectProduct: URL + "api/Management/Products/RejectProduct", //商品登録拒否
  SetProductEnableOrDisable: (productId, enabled) => URL + `api/Management/Products/SetProductEnableOrDisable/${productId}/${enabled}`, //设置商品为可用或不可用
  GetMerchantCategoryList: URL + "api/Management/Categories/GetMerchantCategoryList", //カテゴリリスト(根据商店)

  //注文管理
  GetOrderList: URL + "m_getOrderList", //注文リスト
  GetOrderDetail: URL + "m_getOrder", //注文詳細
  AddOrder: URL + "api/Management/Orders/AddOrder", //注文新規
  UpdateOrder: URL + "m_updateOrder", //注文編集
  ConfirmOrder: URL + "m_confirmOrder", //注文確認
  SendProducts: URL + "m_sendProducts", //注文发货
  CancelOrder: URL + "m_cancelOrder", //商点からの注文中止
  FinishOrder: URL + "api/Management/Orders/FinishOrder", //注文完了
  ClosedOrder: URL + "m_finishOrder", //注文完了
  m_refundOrder70to90: URL + "m_refundOrder70to90", //返金確認
  m_refundOrder80to90: URL + "m_refundOrder80to90", //返金確認
  m_disagreeRefundOrder: URL + "m_disagreeRefundOrder", //返金拒否
  DisagreeRefundOrder: URL + "api/Management/Orders/DisagreeRefundOrder", //返金拒否
  RefundOrder: URL + "api/Management/Orders/RefundOrder", //注文返金
  DeleteOrder: URL + "api/Management/Orders/DeleteOrder", //注文削除
  RefundOrder1: URL + "api/Management/Orders/RefundOrder1", //注文返金

  //ユーザー管理
  GetUserList: URL + "api/Management/Users/GetUserList", //ユーザーリスト
  GetUserDetail: URL + `api/Management/Users/GetUserDetail`, //ユーザー詳細
  AddUser: URL + "api/Management/Users/AddUser", //ユーザー新規
  UpdateUser: URL + "api/Management/Users/UpdateUser", //ユーザー編集
  DeleteUser: URL + "api/Management/Users/DeleteUser", //ユーザー削除
  SetUserEnableOrDisable: (userId, enabled) => URL + `api/Management/Users/SetUserEnableOrDisable/${userId}/${enabled}`, //设置ユーザー为可用或者不可用

  //受取人住所管理
  GetAddressList: URL + "api/Management/Addresses/GetAddressList", //受取人住所リスト
  GetAddressDetail: URL + "api/Management/Addresses/GetAddressDetail", //受取人住所詳細
  AddAddress: URL + "api/Management/Addresses/AddAddress", //受取人住所新規
  UpdateAddress: URL + "api/Management/Addresses/UpdateAddress", //受取人住所編集
  DeleteAddress: URL + "m_deleteAddress", //受取人住所削除

  //商店管理
  GetMerchantList: URL + "api/Management/Merchants/GetMerchantList", //商店リスト
  GetMerchantDetail: URL + "api/Management/Merchants/GetMerchantDetail", //商店詳細
  AddMerchant: URL + "api/Management/Merchants/AddMerchant", //商店新規
  UpdateMerchant: URL + "api/Management/Merchants/UpdateMerchant", //商店編集
  SetMerchantEnableOrDisable: (merchantId, enabled) => URL + `api/Management/Merchants/SetMerchantEnableOrDisable/${merchantId}/${enabled}`, //设置商店为无效或有效

  //カテゴリ管理
  GetCategoryList: URL + "api/Management/Categories/GetCategoryList", //カテゴリリスト
  GetCategoryDetail: URL + "api/Management/Categories/GetCategoryDetail", //カテゴリ詳細
  AddCategory: URL + "api/Management/Categories/AddCategory", //新建カテゴリ
  UpdateCategory: URL + "api/Management/Categories/UpdateCategory", //編集カテゴリ
  DeleteCategory: URL + "api/Management/Categories/DeleteCategory", //削除カテゴリ

  //スライド管理
  GetSlideList: URL + "api/Management/Slides/GetSlideList", //スライドリスト
  GetSlideDetail: URL + "api/Management/Slides/GetSlideDetail", //スライド詳細
  AddSlide: URL + "api/Management/Slides/AddSlide", //新建スライド
  UpdateSlide: URL + "api/Management/Slides/UpdateSlide", //編集スライド
  DeleteSlide: URL + "m_deleteSlide", //削除スライド

  //スタッフ管理
  GetStaffList: URL + "api/Management/Staff/GetStaffList", //スタッフリスト
  GetStaffDetail: URL + "api/Management/Staff/GetStaffDetail", //スタッフ詳細
  AddStaff: URL + "api/Management/Staff/AddStaff", //新規スタッフ
  UpdateStaff: URL + "api/Management/Staff/UpdateStaff", //更新スタッフ
  SetStaffEnableOrDisable: (staffId, enabled) => URL + `api/Management/Staff/SetStaffEnableOrDisable/${staffId}/${enabled}`, //设置スタッフ不可用

};
