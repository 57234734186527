<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    // axios.interceptors.request.use(config => {
    //   if (localStorage.getItem('authToken')) {
    //     config.headers.common.authToken = localStorage.getItem('authToken')
    //     config.headers.common.currentRole = localStorage.getItem('currentRole')
    //   } else {
    //     this.$router.push('/GetLogin')
    //   }
    //   return config

    // })
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }

  }
}
</script>
