import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", redirect: "/GetLogin" },
    //ログイン
    { path: "/GetLogin", name: "GetLogin", component: () => import('@/components/Home/GetLogin.vue') },
    {
      path: "/", component: () => import('@/components/Home.vue'), redirect: "/GetProductsList",
      children: [
        //パスワード変更
        { path: "UpdatePassword", name: "UpdatePassword", component: () => import('@/components/Home/UpdatePassword.vue') },

        /*商品管理*/
        { path: "/GetProductsList", name: 'GetProductsList', component: () => import('@/view/Products/GetProductsList.vue') },//商品管理列表
        { path: "/GetProductsDetails", name: 'GetProductsDetails', component: () => import('@/view/Products/GetProductsDetails.vue') },//商品管理詳細

        /*注文管理*/
        { path: "/GetOrdersList", name: 'GetOrdersList', component: () => import('@/view/Orders/GetOrdersList.vue') },//注文管理列表
        { path: "/GetOrdersDetails", name: 'GetOrdersDetails', component: () => import('@/view/Orders/GetOrdersDetails.vue') },//注文管理詳細

        /*ユーザー管理*/
        { path: "/GetUsersList", name: "GetUsersList", component: () => import('@/view/Users/GetUsersList.vue') },//ユーザー管理列表
        { path: "/GetUsersDetails", name: "GetUsersDetails", component: () => import('@/view/Users/GetUsersDetails.vue') },//ユーザー管理詳細

        /*受取人住所管理*/
        { path: "/GetAddressesList", name: 'GetAddressesList', component: () => import('@/view/Addresses/GetAddressesList.vue') },//受取人住所管理列表
        { path: "/GetAddressesDetails", name: 'GetAddressesDetails', component: () => import('@/view/Addresses/GetAddressesDetails.vue') },//受取人住所管理詳細

        /*商店管理*/
        { path: "/GetMerchantsList", name: 'GetMerchantsList', component: () => import('@/view/Merchants/GetMerchantsList.vue') },//商店管理列表
        { path: "/GetMerchantsDetails", name: 'GetMerchantsDetails', component: () => import('@/view/Merchants/GetMerchantsDetails.vue') },//商店管理詳細

        /*カテゴリ*/
        { path: "/GetCategoryList", name: 'GetCategoryList', component: () => import('@/view/Category/GetCategoryList.vue') },//カテゴリ管理リスト
        { path: "/GetCategoryDetails", name: 'GetCategoryDetails', component: () => import('@/view/Category/GetCategoryDetails.vue') },//カテゴリ管理詳細

        /*スライド管理*/
        { path: "/GetSlideList", name: 'GetSlideList', component: () => import('@/view/Slide/GetSlideList.vue') },//スライド管理列表
        { path: "/GetSlideDetails", name: 'GetSlideDetails', component: () => import('@/view/Slide/GetSlideDetails.vue') },//スライド管理詳細

        /*スタッフ*/
        { path: "/GetStaffList", name: "GetStaffList", component: () => import('@/view/Staff/GetStaffList.vue') },//スタッフ列表
        { path: "/GetStaffDetails", name: "GetStaffDetails", component: () => import('@/view/Staff/GetStaffDetails.vue') },//スタッフ詳細
      ]
    },

  ]
});

export default router;
